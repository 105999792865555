import { GoogleLogin } from "@react-oauth/google"
import axios from "axios"
import { useDispatch } from "react-redux"
import { changeUser } from "../redux/userSlice.js"
const URL_API = process.env.REACT_APP_API_URL
const URL = `${URL_API}/google-auth`
const GoogleAuth = () => {
    const dispatch = useDispatch()
    return (

        <GoogleLogin
            onSuccess={credentialResponse => {
                console.log(credentialResponse)
                axios.post(URL, credentialResponse, { withCredentials: true })
                    .then((res) => {
                        dispatch(changeUser(res.data.user))
                        //window.location.replace("http://localhost:5000")
                        window.location.replace("https://www.meetingsvault.saloit.com")
                    })

            }}
            onError={() => {
                console.log("Login Failed")
            }} />
    )
}

export default GoogleAuth