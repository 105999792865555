import { useEffect } from "react"
import Cookies from "universal-cookie"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { changeUser, changeProyects } from "../redux/userSlice.js"
import LanguageSelector from "./LanguageSelector.jsx"
import { useTranslation } from "react-i18next"
// import logo from "@images/logoMeetInOrder2.png"
import logo from "@images/nrmv.png"
import { Link } from "react-router-dom"
function NavigBar() {
    const { t } = useTranslation(["trNavigBar"]);
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user.name)
    const cookies = new Cookies()
    const URL_API = process.env.REACT_APP_API_URL
    useEffect(() => {
        if (cookies.get("token")) {
            axios.get(`${URL_API}/auth`, {
                withCredentials: true
            })
                .then((res) => {
                    if (res.data === "expired") {
                        dispatch(changeUser(""))
                        //window.location.replace("http://localhost:5000")
                        window.location.replace("https://www.meetingsvault.saloit.com")
                    } else {
                        dispatch(changeUser(res.data.user))

                    }

                })
        }
    })

    const cerrarSesion = () => {
        if (cookies.get("token")) {
            cookies.remove("token",{domain:".saloit.com"})
            //cookies.remove("token")
            dispatch(changeUser(""))
            dispatch(changeProyects([]))
            //window.location.replace("http://localhost:5000")
            window.location.replace("https://www.meetingsvault.saloit.com")
        }
    }

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary sticky-top">
            <div className="container-fluid navigBar">

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center align-content-center align-items-center" id="navbarNav">
                    <ul className="navbar-nav d-inline-block container-fluid">
                        <div className="d-flex justify-content-evenly row">
                            <div className="col-lg-3 col-3">
                                <ul className="nav nav-justified">
                                    <li className="nav-item">
                                        <Link to={"/"}><img src={logo} id="navLogo" alt="" /></Link>
                                        {/* <p className="nav-link active" aria-current="page"><button type="button" className="btn"><a href="/" style={{ textDecoration: "none", color: "black" }}>{t("navHome")}</a></button></p> */}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-8 col-6">
                                <ul className="nav  nav-justified">
                                    {user ? <>
                                        <li className="nav-item">
                                            <p className="nav-link active" aria-current="page"><button type="button" className="btn" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">{t("navMyProjects")}</button></p>
                                        </li>
                                        <li className="nav-item">
                                            <p className="nav-link active" aria-current="page"><button type="button" className="btn" onClick={cerrarSesion}>{t("navLogOut")}</button></p>
                                        </li></> :
                                        <li className="nav-item navBtn"><a className="nav-link active" aria-current="page" href="/login"><button type="button" className="btn btn-primary myBtn">{t("navLogin")}</button></a></li>
                                    }
                                    <li className="nav-item" id="guidesNav"><a className="nav-link active" aria-current="page" href="/allguides">{t("navGuides")}</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-1 col-3">
                                <li className="nav-item">
                                    <div className="nav-link"><LanguageSelector /></div>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavigBar

