import { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import axios from "axios"
import { useTranslation } from "react-i18next"
import swal from "sweetalert"
function ResetPass() {
    const { t } = useTranslation(["trPassRecovery"]);
    let [searchParams,] = useSearchParams()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const URL_API = process.env.REACT_APP_API_URL
    const URL = `${URL_API}/userauth/validateRecovery`
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [warning, setWarning] = useState(false)
    const [warning2, setWarning2] = useState(false)
    const [warning3, setWarning3] = useState(false)
    const [warning4, setWarning4] = useState(false)


    useEffect(() => {
        let message3=t("message3")
        axios.post(URL, { code: searchParams.get("key") }, { withCredentials: true })
            .then((res) => {
                if (res.data.error) {
                    setLoading(false)
                    setError(<p style={{ color: "red" }}>{message3}</p>)
                } else {
                    setLoading(false)
                }

            })
    }, [searchParams])
    const recoveryPassword = () => {
        let rePassword=document.getElementById("rePassword").value
        if(email===""){
            setWarning2(true)
            setWarning3(false)
            setWarning4(false)
        }else if(password===""){
            setWarning3(true)
            setWarning2(false)
            setWarning4(false)
        }else if(rePassword===""){
            setWarning4(true)
            setWarning2(false)
            setWarning3(false)
        }else{
            setWarning4(false)
            setWarning2(false)
            setWarning3(false)
            axios.post(`${URL_API}/userauth/resetPass`,{code:searchParams.get("key"),email:email,pass:password})
            .then((res)=>{
                if(res.data.error){
                    swal(t("warning2"))
                }else{
                    swal(t("success"))
                    .then(()=>{
                        //window.location.replace("http://localhost:5000")
                        window.location.replace("https://www.meetingsvault.saloit.com")
                    })
                }
                
            })
        }
    }
    const styleWarning = (e) => {
        if (password === e.target.value) {
            setWarning(false)
        } else {
            setWarning(true)

        }
    }
    return (
        <div className="container-fluid loginPage">
            <div className="row align-content-center justify-content-center" style={{ height: "86vh", paddingTop: "10%" }}>
                <div className="col-lg-3 border border-3 shadow" style={{ background: "white" }}>
                    {loading ?
                        <div className="spinner-border" role="status">
                            <span>Verificando un momento por favor</span>
                        </div> : error ? <h3><strong>{error}</strong></h3> :
                            <>
                                <div className="row justify-content-center px-3 py-2">
                                    <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
                                    {warning2?<span style={{color:"red"}}>{t("warning")}</span>:null}
                                </div>
                                <div className="row justify-content-center px-3 py-2">
                                    <input type="password" className="form-control" id="exampleFormControlInput3" placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
                                    {warning3?<span style={{color:"red"}}>{t("warning")}</span>:null}
                                </div>
                                <div className="row justify-content-center px-3 py-2">
                                    <input type="password" className="form-control" style={warning ? { borderColor: "red" } : null} id="rePassword" placeholder="Contraseña" onChange={styleWarning} />
                                    {warning4?<span style={{color:"red"}}>{t("warning")}</span>:null}
                                </div>
                                <div className="row justify-content-center px-3 py-2">
                                    <button type="button" className="btn btn-primary myBtn" onClick={recoveryPassword}>{t("btnSend")}</button>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default ResetPass