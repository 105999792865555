import Contact from "./Contact";
import Footer from "./Footer";
import GuiaDeUso from "./GuiaDeUso";
import NewProject from "./NewProject";

function LandingPage() {
    return (
        <>
            <NewProject />
            <GuiaDeUso />
            <Contact/>
            <Footer/>
        </>
    )
}

export default LandingPage