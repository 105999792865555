import { useLocation } from "react-router"
import axios from "axios"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
function Validator() {
    const {t} = useTranslation(["trValidator"]);
    const navigate = useNavigate()
    let [error,setError]=useState("")
    const location = useLocation()
    const data = location.state
    const URL_API = process.env.REACT_APP_API_URL
    const handleValidator=()=>{
        let code=document.getElementById("inputCode").value
        console.log(code,"         ",data.email)
        let info={
            code,
            email:data.email
        }
        axios.post(`${URL_API}/userauth/validator`,info,{withCredentials:true})
        .then((res)=>{
            if(res.data.error){
                setError(res.data.error)
            }else{
                navigate("/")
            }
        })
    }
    return (
        <div className="container-fluid loginPage" style={{ "paddingTop": "15%" }}>
            {error?<div className="row align-items-center justify-content-center">
                <div className="col-auto">
                    <span id="passwordHelpInline" className="form-text" style={{color:"red"}}>
                        {error}, {t("pError")} <Link to={"/newaccount"}>{t("pError2")}</Link> {t("pError3")}<br />
                    </span>
                </div>
            </div>:null}
            <div className="row g-3 align-items-center justify-content-center">
                <div className="col-auto">
                    <input type="text" id="inputCode" className="form-control" aria-describedby="passwordHelpInline" style={{width:"36%",margin:"auto"}} />
                </div>
            </div>
            <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                    <span id="passwordHelpInline" className="form-text">
                        {t("pInfo")} <br />
                        {t("pInfo2")}
                    </span>
                </div>
            </div>
            <div className="row pt-3">
                <div className="col">
                    <button type="button" className="btn btn-primary myBtn" onClick={handleValidator}>
                        {t("btnSend")}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Validator