import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import vidNewProject from "@images/vidNewProjectGuide.gif"
import vidNewProject2 from "@images/vidNewMeetingGuide.gif"
import vidNewProject3 from "@images/vidNewEditionPage.gif"
import useScreensize from "../hooks/useScreenSize"
import { useState } from "react"
function GuiaDeUso() {
    const useScreen=useScreensize()
    const { t } = useTranslation(["trUserGuide"]);
    const [imgSelected,setImgSelected]=useState("")
    return (
        <div className="container-fluid" id="homeGuiaDeUso">
            <div className="row">
                <div className="col my-5">
                    <h2 id="homeGuiaDeUsoTitle">{t("title")}</h2>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-12">
                    <div className="row">
                        <div className="col-1">
                            <button type="button" className="btn btn-primary myCarouselBtn" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev" data-bs-theme="dark">
                                {useScreen?<span aria-hidden="true">&#60;</span>:<span className="carousel-control-prev-icon" aria-hidden="true"></span>}
                                <span className="visually-hidden">Previous</span>
                            </button>
                        </div>
                        <div className="col-10">
                            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="100000">
                                        <div className="row">
                                            <div className="col-md">
                                                <h3 className="homeGuiaSubTitle">{t("subtitle1")}</h3>
                                                <p>{t("p1")}</p>
                                                <p>{t("p2")}</p>
                                                <p>{t("p3")}</p>
                                                <p>{t("p4")}</p>
                                            </div>
                                            <div className="col-md">
                                                {useScreen?<button className="allGuidesgifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModalHome" onClick={(e)=>setImgSelected(e.target.src)}><img src={vidNewProject} className="d-block w-100" alt="" /></button>:<img src={vidNewProject} className="d-block w-100" alt="" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="100000">
                                        <div className="row">
                                            <div className="col-md">
                                                <h3>{t("subtitle2")}</h3>
                                                <p>{t("p5")}</p>
                                                <p>{t("p6")}</p>
                                            </div>
                                            <div className="col-md">
                                            {useScreen?<button className="allGuidesgifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModalHome" onClick={(e)=>setImgSelected(e.target.src)}><img src={vidNewProject2} className="d-block w-100" alt="" /></button>:<img src={vidNewProject2} className="d-block w-100" alt="" />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="100000">
                                        <div className="row">
                                            <div className="col-md">
                                                <h3>{t("subtitle3")}</h3>
                                                <p>{t("p7")}</p>
                                                <p>{t("p8")}</p>
                                                <p>{t("p9")}</p>
                                            </div>
                                            <div className="col-md">
                                            {useScreen?<button className="allGuidesgifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModalHome" onClick={(e)=>setImgSelected(e.target.src)}><img src={vidNewProject3} className="d-block w-100" alt="" /></button>:<img src={vidNewProject3} className="d-block w-100" alt="" />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1">
                            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next" data-bs-theme="dark" className="btn btn-primary myCarouselBtn">
                            {useScreen?<span aria-hidden="true">&#62;</span>:<span className="carousel-control-next-icon" aria-hidden="true"></span>}
                                <span className="visually-hidden">Previous</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col">
                   <Link to={"/allguides"}><button type="button" className="btn btn-primary myBtn">{t("btnSeeAll")}</button></Link>
                </div>
            </div>
            {/* mobile modal */}
            <div className="modal fade" id="gifModalHome" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-body" id="allGuidesModalBody" >
                            <div className="row justify-content-end">
                                <div className="col-3">
                                    <button type="button" id="allGuidesCloseBtn" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                </div>
                            </div>
                            <div className="row align-items-center allGuidesDivGif" >
                                        <div className="col-12">
                                            <img src={imgSelected} className="d-block w-100" alt="" />
                                        </div>
                                    </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuiaDeUso