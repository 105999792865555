import GoogleAuth from "./GoogleOauth"
import swal from "sweetalert"
import axios from "axios"
import { useRef,useState } from "react"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import { useTranslation } from "react-i18next"
function Login() {
    const { t} = useTranslation(["trLogin"]);
    const URL_API = process.env.REACT_APP_API_URL
    const URL = `${URL_API}/userauth/monologin`
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")
    const target = useRef(null)
    const target2 = useRef(null)
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)

    const handleLogin = () => {
        if(userEmail===""){
            setShow(true)
            setTimeout(()=>{
                setShow(false)
            },2000)
        }else if(userPassword===""){
            setShow2(true)
            setTimeout(()=>{
                setShow2(false)
            },2000)
        }
        else{
        const data = {
            userEmail,
            userPassword
        }
        axios.post(URL, data, { withCredentials: true })
            .then((res) => {
                if (res.data.error) {
                    swal(res.data.error)
                } else {
                    window.location.replace("https://www.meetingsvault.saloit.com")
                    //window.location.replace("http://localhost:5000")
                }
            })
        }
    }
    return (
        <div className="container-fluid loginPage" id="loginPage">
            <div className="row justify-content-center" style={{paddingTop:"10%"}}>
                <div className="col-lg-3 border border-3 shadow" style={{backgroundColor:"white"}}>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <h3>MeetingsVault</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-3">
                        {t("subTitle")}
                    </div>
                    <form>
                        <div className="row justify-content-center px-3 py-2">
                            <input ref={target} type="email" className="form-control" id="exampleFormControlInput1" placeholder={t("phEmail")} onChange={(e) => setUserEmail(e.target.value)} />
                        </div>
                        <div className="row justify-content-center px-3 py-2">
                            <input ref={target2} type="password" className="form-control" id="exampleFormControlInput2" placeholder={t("phPassword")}  onChange={(e) => setUserPassword(e.target.value)} />
                        </div>
                        <div className="row justify-content-center px-3 py-2">
                            <button type="button" className="btn btn-primary myBtn" onClick={handleLogin}>{t("btnContinue")}</button>
                        </div>
                    </form>
                    <div className="row justify-content-center py-2">
                        {t("pContinuar")}
                    </div>
                    <div className="row py-2">
                        <div className="col-lg-4 col-12" id="loginGoogleBtn" >
                            <GoogleAuth />
                        </div>
                    </div>
                    <div className="row mt-2 px-5">
                        <hr />
                    </div>
                    <div className="row px-3 pb-3 justify-content-center">
                        <div className="col-5"><a className="nav-link active" aria-current="page" href="/forgotPassword">{t("btnCantLogin")}</a></div>
                        <div className="col-2 w-auto">•</div>
                        <div className="col-5"><a className="nav-link active" aria-current="page" href="/newaccount">{t("btnCreateAccount")}</a></div>
                    </div>
                </div>
            </div>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("tooltips")}
                    </Tooltip>
                )}
            </Overlay>
            <Overlay target={target2.current} show={show2} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("tooltips")}
                    </Tooltip>
                )}
            </Overlay>
        </div>
    )
}

export default Login