import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
function NameProject() {
    const { t } = useTranslation(["trNewProject"]);
    const location = useLocation()
    const data = location.state
    const [proyName, setProyName] = useState(data ? data.proyName : "")
    const [participantList, setParticipantList] = useState([])
    const [meetingName, setMeetingName] = useState("")
    const [participantName, setParticipantName] = useState("")
    const addParticipant = () => {
        let partName = document.getElementById("inputName").value
        let partEmail = document.getElementById("inputEmail").value
        let partRole = document.getElementById("inputRole").value
        let obj = { partName, partEmail, partRole }
        setParticipantList(participantList => [...participantList, obj])
        document.getElementById("inputName").value = ""
        document.getElementById("inputEmail").value = ""
        document.getElementById("inputRole").value = ""
        setParticipantName("")
    }
    const deleteParticipant = (index) => {

        let deletePartName = [...participantList]
        deletePartName.splice(index, 1)

        setParticipantList(deletePartName)
    }
    return (
        <div className="container-fluid justify-content-center align-items-center reportPages">
            {/* agregar en algun lado el copyright del patron de fondo  <a href="https://www.freepik.com/free-photo/light-gray-concrete-wall_4139268.htm">Image by rawpixel.com</a> on Freepik */}
            <div className="row justify-content-center align-items-center py-5">
                <div className="col-auto">
                    <div className="card formStyles">
                        <div className="card-body">
                            <h5 className="card-title">{t("title")}</h5>
                            <div className="row my-2">
                                {data ?
                                    <div className="col-auto">
                                        <input className="form-control" type="text" value={proyName} aria-label="readonly input example" readOnly></input>
                                    </div>
                                    : <div className="col-auto">
                                        <label htmlFor="inputProjectName" className="visually-hidden">{t("phNameProject")}</label>
                                        <input type="text" className="htmlForm-control" id="inputProjectName" placeholder={t("phNameProject")} onChange={(e) => (setProyName(e.target.value))} />
                                    </div>
                                }
                            </div>
                            <div className="row my-2">
                                <div className="col-auto">
                                    <label htmlFor="inputMeetingName" className="visually-hidden">Email</label>
                                    <input type="text" className="htmlForm-control" id="inputMeetingName" placeholder={t("phMeetingName")} onChange={(e) => (setMeetingName(e.target.value))} />
                                </div>
                            </div>
                            <form className="row justify-content-between g-3 pt-5">

                                <div className="col-auto">
                                    <label htmlFor="inputName" className="visually-hidden">Nombre</label>
                                    <input type="text" className="htmlForm-control" id="inputName" onChange={(e) => { setParticipantName(e.target.value) }} placeholder={t("phParticipant")} />
                                </div>
                                <div className="col-auto">
                                    <label htmlFor="inputName" className="visually-hidden">Nombre</label>
                                    <input type="text" className="htmlForm-control" id="inputRole" placeholder={t("phRole")} />
                                </div>
                                <div className="col-auto">
                                    <label htmlFor="inputEmail" className="visually-hidden">Email</label>
                                    <input type="text" className="htmlForm-control" id="inputEmail" placeholder={t("phEmail")} />
                                </div>
                                <div className="col-auto">
                                    <button disabled={participantName ? false : true} type="button" style={{ color: "white" }} className="btn myBtn mb-3" onClick={addParticipant}>{t("btnAdd")}</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row justify-content-center align-items-center">
                <div className="col-auto">
                    <div className="card" style={{ width: "18rem" }}>
                        <ul className="list-group list-group-flush">
                            {participantList.map((e, index) => (

                                <li className="list-group-item NameProjectList" key={index}>
                                    <div className="row">
                                        <div className="col-8">{e.partName}</div>
                                        <div className="col-1"><button type="button" className="btn  btn-sm ms-3" id={index} value={index} onClick={(e) => deleteParticipant(e.target.value)}><svg style={{ color: "#df3b3b" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
                                        </svg></button></div></div></li>

                            ))}

                        </ul>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center align-items-center my-5">
                <div className="col-auto">
                    <div className="col-auto">
                        <button disabled={meetingName ? false : true} type="submit" className="btn mb-3 myBtn"> <Link to={"/taskList"} state={{ meetingName, participantList, proyName }} style={{ textDecoration: "none", color: "white" }}> {t("btnNext")}</Link></button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default NameProject