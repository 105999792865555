import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux"
import {store} from "./redux/store.js"
import { Suspense } from "react"
import "./i18.js"
import {GoogleOAuthProvider} from "@react-oauth/google"
const clientId="871670602594-k6bdt00t2f8ehqhn3u6477knu77a8uvq.apps.googleusercontent.com"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <Suspense fallback="....Loading">
  <GoogleOAuthProvider clientId={clientId}>
  <Provider store={store}>
    <App />
  </Provider>
  </GoogleOAuthProvider>
  </Suspense>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
