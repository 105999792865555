import axios from "axios"
import { useRef, useState, useEffect } from "react"
import Cookies from "universal-cookie"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import { useSelector, useDispatch } from "react-redux"
import { changeUser, changeProyects } from "../redux/userSlice.js"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const cookies = new Cookies()
const ProyectHistory = () => {
    const { t} = useTranslation(["trProjectHistory"]);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const proyects = useSelector((state) => state.user.proyects)
    const user = useSelector((state) => state.user.name)
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const target = useRef(null)
    const target2=useRef(null)
    const URL_API=process.env.REACT_APP_API_URL
    useEffect(() => {
        if (cookies.get("token")) {
            axios.get(`${URL_API}/auth`, {
                withCredentials: true
            })
                .then((res) => {
                    if (res.data === "expired") {
                        dispatch(changeUser(""))
                        //window.location.replace("http://localhost:5000")
                        window.location.replace("https://www.meetingsvault.saloit.com")

                    } else {
                        dispatch(changeUser(res.data.user))

                        getProyectList()

                    }

                })
        }
    },[user])
    const addProyect = () => {
        let proyectName = document.getElementById("inputProyectName").value
        let clientName = document.getElementById("inputClientName").value
        if(proyectName===""||clientName===""){
            setShow2(true)
            setTimeout(() => {
                setShow2(false)
            }, 4000)
        }else{
        axios.post(`${URL_API}/api/addproyect`, { proyectName,clientName }, {
            withCredentials: true
        }).then((res) => {

            if (res.data.data === "duplicado") {
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 4000)
            } else {
                document.getElementById("inputProyectName").value = ""
                document.getElementById("inputClientName").value=""
                document.getElementById("closeModal").click()
                getProyectList()
                navigate("/allmyproyects")
            }
        })}
    }
    const getProyectList = () => {
        axios.get(`${URL_API}/api/getproyectlist`, {
            withCredentials: true
        }).then((res) => {
            if (res.data.data) {
                dispatch(changeProyects(res.data.data))
                
            }
        })
    }

    const goToProyect = (e) => {
        const param1 = e.target.id
        let param2=e.target.getAttribute("tag")
        console.log(param1)
        if (param1 === "all") {
            navigate("/allmyproyects")
        } else {
            navigate("/proyectmanager", { state: { param1: e.target.id,param2 } })
        }
    }
    
    return (
        <div className="phistorySideBar">
            <div className="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
                <div className="offcanvas-header">
                    <h3 className="offcanvas-title" id="offcanvasScrollingLabel"><button type="button" className="btn btn-primary myBtn" id="addProyectModalBtn" data-bs-toggle="modal" data-bs-target="#modAddProyect">{t("ocNewProject")}</button></h3>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
               <div className="offcanvas-body">
                    <div className="container">
                        {proyects.slice(0,5).map((e) => (
                            <div className="row" key={e.proyId.name}>
                                <div className="col-6">
                                    <button type="button" className="btn" id={e.proyId.name} tag={e.proyId._id} onClick={goToProyect}>{e.proyId.name}</button>
                                </div>
                            </div>
                        ))}
                        <button id="all" style={{ fontSize: "10px", color: "blue", textDecoration: "underline" }} type="button" className="btn" onClick={goToProyect}>{t("btnAllProjects")}</button>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modAddProyect" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">{t("modProjectName")}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <input style={{marginRight:"5px"}} ref={target} type="text" data-bs-toogle="tooltip" data-bs-title="toooooltip" className="htmlForm-control" id="inputProyectName" placeholder={t("modProjectName")} required />
                            <input type="text" className="htmlForm-control" id="inputClientName" placeholder={t("modClient")} required/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id="closeModal">{t("modClose")}</button>
                            <button type="button" className="btn myBtn" ref={target2} onClick={addProyect}>{t("modSave")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("overlay")}
                    </Tooltip>
                )}

            </Overlay>
            <Overlay target={target2.current} show={show2} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("overlay2")}
                    </Tooltip>
                )}

            </Overlay>
        </div>
    )
}

export default ProyectHistory