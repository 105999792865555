import { useTranslation } from "react-i18next"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import axios from "axios"
import { useState,useRef } from "react";
import swal from "sweetalert"
function Contact() {
    const { t } = useTranslation(["trContact"]);
    const [suppSelect, setSuppSelect] = useState(false)
    const [userEmail, setUserEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    const target = useRef(null)
    const URL_API = process.env.REACT_APP_API_URL
    const URL = `${URL_API}/api`
    const handleSendMsg = () => {
        setLoading(true)
        let msg = document.getElementById("msg").value
        let url
        let data
        let lang = localStorage.getItem("i18nextLng")
        if (suppSelect) {
            if(!userEmail){
                setShow(true)
            }else{
                setShow(false)
            }
            url = `${URL}/sendMsgSupport`
            data = {
                userEmail,
                msg,
                lang
            }
        } else {
            url = `${URL}/sendMsg`
            data = { msg }
        }
        if(msg){
        axios.post(url, data, { withCredentials: true })
            .then((res) => {
                document.getElementById("msg").value = ""
                setUserEmail("")
                setLoading(false)
                swal({ icon: "success" })
            })}else{
                setLoading(false)
            }
    }
    const handleSelect = (e) => {
        if (e.target.value === "2") {
            setSuppSelect(true)
        } else {
            setSuppSelect(false)
        }
    }
    return (
        <div className="container-fluid" id="homeContacto">
            <div className="row justify-content-around  pt-5 pb-5">
                <div className="col-lg-3 col-12 mt-5" style={{ textAlign: "justify" }}>
                    <p>{t("p1")}</p>
                    <p>{t("p2")}</p>
                    <p>{t("p3")}</p>
                </div>
                <div className="col-lg-6 col-12 mt-5">
                    <div className="row gap-1">
                        <div className="col-lg-3 col-12">
                            <select className="form-select" aria-label="Default select" id="msgSelect" onChange={(e) => handleSelect(e)}>
                                <option defaultValue="1">{t("selectOp1")}</option>
                                <option value="2">{t("selectOp2")}</option>
                            </select>
                        </div>
                        {suppSelect ?
                            <div className="col-lg-3 col-12">
                                <input ref={target} type="email" className="form-control" id="inputUserMail" placeholder="E-mail" onChange={(e) => setUserEmail(e.target.value)} />
                            </div>
                            : null
                        }
                    </div>
                    <div className="row mt-2">
                        <div className="col-lg-10 col-12">
                            <div className="mb-3">
                                <label htmlFor="FormControlTextarea1" className="form-label">{t("msgTitle")}</label>
                                <textarea className="form-control" id="msg" rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end">
                        <div className="col-lg-2 col-4">
                            <div className="row justify-content-center">
                                {loading ?
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> :
                                    <button type="button" className="btn myBtn" onClick={handleSendMsg}>{t("sendBtn")}</button>}
                            </div>
                        </div>
                        <div className="col-lg-2 col-1"></div>
                    </div>
                </div>
            </div>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("overlay")}
                    </Tooltip>
                )}

            </Overlay>
        </div>
    )
}

export default Contact