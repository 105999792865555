import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { changeProyects } from "../redux/userSlice.js"
import axios from "axios"
import MeetingMini from "./MeetingMini"
import swal from "sweetalert2"
import { useTranslation } from "react-i18next"
import { handleExpired } from "../functions/functions.js"
function ProyectManage() {
    const { t } = useTranslation(["trProjectManage"]);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state
    const [meetings, setMeetings] = useState([])
    const [selectedMeeting, setSelectedMeeting] = useState(null)
    const URL_API = process.env.REACT_APP_API_URL

    useEffect(() => {
        axios.post(`${URL_API}/api/getMeetingList`, { data }, {
            withCredentials: true
        })
            .then((res) => {
                if (handleExpired(res.data.data)) {
                    setMeetings(res.data.data.meetings)
                    setSelectedMeeting(null)
                }
            })
    }, [data])
    const renderSelected = (event) => {
        setSelectedMeeting(event.target.value)

    }
    const editMeetingHandler = (event) => {
        let lang = localStorage.getItem("i18nextLng")
        let meetIdentId = event.target.getAttribute("name") ? event.target.getAttribute("name") : meetings[selectedMeeting].proyId._id
        axios.post(`${URL_API}/api/getmeeting`, { meetingId: meetIdentId, lang }, {
            withCredentials: true
        })
            .then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal.fire(res.data.alert)
                    } else {
                        console.log("++++++*****", res)
                        navigate("/meetingedit", { state: { meetid: res.data.data, logoUrl: res.data.logoImg } })
                    }
                }
            })

    }
    const handleNewMeeting = () => {
        let lang = localStorage.getItem("i18nextLng")
        axios.post(`${URL_API}/api/checkproyectowner`, { proyId: data.param2, lang }, {
            withCredentials: true
        })
            .then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal.fire(res.data.alert)
                    } else {
                        navigate("/NewProy", { state: { proyName: data.param1 } })
                    }
                }
            })
    }
    const handleCloseProyect = () => {
        let lang = localStorage.getItem("i18nextLng")

        swal.fire({
            title:t("swalTitle"),
            text: t("swalText"),
            showCancelButton: true,
            confirmButtonText: t("swalBtn"),
            icon:"warning"
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${URL_API}/api/closeproyect`, { proyId: data.param2, lang }, {
                    withCredentials: true
                })
                    .then((res) => {
                        if (handleExpired(res.data.data)) {
                            if (res.data.alert) {
                                swal.fire(res.data.alert)
                            } else {
                                axios.get(`${URL_API}/api/getproyectlist`, {
                                    withCredentials: true
                                }).then((res) => {
                                    if (res.data.data) {

                                        dispatch(changeProyects(res.data.data))

                                    }
                                })
                                navigate("/allmyproyects")
                            }
                        }
                    })
            }
        })


    }
    const handleDeleteMeeting = () => {
        let lang = localStorage.getItem("i18nextLng")
        axios.post(`${URL_API}/api/deleteMeeting`, { proyId: meetings[selectedMeeting].proyId._id, lang }, { withCredentials: true })
            .then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal.fire(res.data.alert)
                    } else {
                        axios.post(`${URL_API}/api/getMeetingList`, { data }, {
                            withCredentials: true
                        })
                            .then((res) => {
                                setMeetings(res.data.data.meetings)
                                setSelectedMeeting(null)
                            })
                    }
                }
            })
    }

    return (
        <div className="container-fluid menuProjects">
            <div className="row py-lg-5 py-2">
                <div className="col-4">
                    <div className="row">
                        <h3><p>{data.param1}</p></h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2 mt-lg-5">
                    <div className="row" style={{ height: "100%" }}>
                        <div className="col">
                            <button type="button" className="btn btn-primary myBtn" disabled={data.param3} onClick={handleNewMeeting}>{t("btnNewMeeting")}</button> <br /><br />
                            <button type="button" className="btn btn-primary myBtn" disabled={data.param3} onClick={handleCloseProyect}>{t("btnClose Project")}</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="row">
                        <h4>{t("pMeetingList")}</h4>
                    </div>
                    <div className="row justify-content-center">
                        <div className="row">
                            <div className="col-12">
                                <select className="form-select" touchUi={false} display="inline" size="10" onChange={renderSelected}>
                                    {
                                        meetings.map((e, index) => (
                                            <option value={index} key={index} style={{ fontSize: "small" }} name={e.proyId._id} onDoubleClick={editMeetingHandler}>{e.proyId.dbName}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {selectedMeeting ? <button className="btn btn-primary myBtn" onClick={handleDeleteMeeting}>{t("btnDelete")}</button> : null}
                            </div>
                            <div className="col">
                                {selectedMeeting ? <button className="btn btn-primary myBtn" onClick={editMeetingHandler}>{t("btnOpen")}</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg col-12">
                    {selectedMeeting ?
                        <MeetingMini meeting={meetings[selectedMeeting]} />
                        : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProyectManage