import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
// import swal from "sweetalert"
import swal from "sweetalert2"
import axios from "axios"
import Overlay from "react-bootstrap/Overlay"
import Tooltip from "react-bootstrap/Tooltip"
import { useTranslation } from "react-i18next"
function CreateAccount() {
    const { t } = useTranslation(["trCreateAccount"]);
    const navigate = useNavigate()
    const URL_API = process.env.REACT_APP_API_URL
    const URL = `${URL_API}/userauth`
    const [password, setPassword] = useState("")
    const [validate, setValidate] = useState(true)
    const [validate1, setValidate1] = useState(true)
    const [warning, setWarning] = useState(false)
    const [warning2, setWarning2] = useState(false)
    const [email, setEmail] = useState("")
    const [show, setShow] = useState(false)
    const [passwordUpp, setPasswordUpp] = useState(false)
    const [passwordLow, setPasswordLow] = useState(false)
    const [passwordNum, setPasswordNum] = useState(false)
    const [passwordCha, setPasswordCha] = useState(false)
    const [passwordLen, setPasswordLen] = useState(false)
    const target = useRef(null)

    const styleWarning = (e) => {
        if (password === e.target.value) {
            setWarning(false)
            setValidate(false)
        } else {
            setWarning(true)
            setValidate(true)
        }
    }
    const handleValidation = (e) => {
        setPassword(e.target.value)
        setPasswordUpp(new RegExp(/[A-Z]/).test(e.target.value))
        setPasswordLow(new RegExp(/[a-z]/).test(e.target.value))
        setPasswordNum(new RegExp(/[0-9]/).test(e.target.value))
        setPasswordCha(new RegExp(/[.@#?!_-]/).test(e.target.value))
        if (e.target.value.length < 8) {
            setPasswordLen(false)
        } else {
            setPasswordLen(true)
        }
    }
    const verifyPass = () => {
        if (passwordUpp && passwordLow && passwordNum && passwordCha && passwordLen) {
            setValidate1(false)
        } else {
            setValidate1(true)
        }
    }
    const handleRegister = async () => {
        let lang = localStorage.getItem("i18nextLng")
        let en=true
        if(lang==="es"){
            en=false
        }
        const { value: accept } = await swal.fire({
            title: t("termsTitle"),
            input: "checkbox",
            inputValue: 0,
            inputPlaceholder: en?`I Accept the <a href="/resources/PrivacyPolicy/PrivacyPolicymeetingsvault.pdf" target="_blank">terms and Conditions</a>`:`Acepto los <a href="/resources/PrivacyPolicy/PoliticaPrivacidadmeetingsvault.pdf" target="_blank">terminos y condiciones</a> y las politicas de privacidad</a>`,
            confirmButtonText: t("termsContinue"),
            customClass: {
                confirmButton: "myBtn"
            },
            showCancelButton: true,
            inputValidator: (result) => {
                return !result && t("termsWarn")
            }
        })
        if (accept) {
            const data = {
                email,
                password,
                lang
            }
            axios.post(URL, data, { withCredentials: true })
                .then((res) => {
                    console.log(res)
                    if (res.data.error) {
                        setShow(true)
                        setWarning2(true)
                    } else if (res.data.alert) {
                        swal.fire(res.data.alert)
                    }
                    else {
                        let msg=t("swalMsg")
                        setShow(false)
                        setWarning2(false)
                        swal.fire(msg)
                            .then(() => { navigate("/validator", { state: { email: email } }) })
                    }

                })
        }



    }

    return (
        <div className="container-fluid loginPage" id="accountPage">
            <div className="row justify-content-center" style={{ paddingTop: "10%" }}>
                <div className="col-lg-4 col-12 border border-3 shadow" style={{ backgroundColor: "white" }} >
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <h3>MeetingsVault</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            {t("createAccount")}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <input ref={target} type="email" className="form-control" style={warning2 ? { borderColor: "red" } : null} id="exampleFormControlInput1" placeholder={t("phEmail")} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col">
                            <input type="password" className="form-control" id="exampleFormControlInput2" placeholder={t("phPassword")} onMouseMoveCapture={verifyPass} onChange={(e) => handleValidation(e)} />
                            <div className="row">
                                <div className="col">
                                    <ul className="passList">
                                        <li style={passwordLen ? { color: "green" } : { color: "red" }}>{t("passVal1")}</li>
                                        <li style={passwordNum ? { color: "green" } : { color: "red" }}>{t("passVal2")}</li>
                                        <li style={passwordCha ? { color: "green" } : { color: "red" }}>{t("passVal3")}</li>
                                    </ul>
                                </div>
                                <div className="col">
                                    <ul className="passList">
                                        <li style={passwordUpp ? { color: "green" } : { color: "red" }}>{t("passVal4")}</li>
                                        <li style={passwordLow ? { color: "green" } : { color: "red" }}>{t("passVal5")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <input type="password" className="form-control" style={warning ? { borderColor: "red" } : null} id="repeatPassword" placeholder={t("phPassword2")} onChange={styleWarning} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <button type="button" className="btn btn-primary myBtn" disabled={validate || validate1} onClick={handleRegister}>{t("Register")}</button>
                        </div>
                    </div>
                    <div className="row mt-2 px-5">
                        <hr />
                    </div>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <a className="nav-link active" aria-current="page" href="/login">{t("login")}</a>
                        </div>
                    </div>
                </div>
            </div>
            <Overlay target={target.current} show={show} placement="top">
                {(props) => (
                    <Tooltip id="OverlayEx" {...props}>
                        {t("overlay")}
                    </Tooltip>
                )}

            </Overlay>
        </div>
    )
}

export default CreateAccount