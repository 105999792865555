import { Link } from "react-router-dom"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"
import useScreensize from "../hooks/useScreenSize"


function NewProject() {
    const [cookies,] = useCookies([`token`])
    const { t } = useTranslation(["trBanner"]);
    const screensize = useScreensize()
    return (
        <div className="container-fluid" id="homePageBanner" style={{ position: "relative" }}>
            <div className="row text-start m" style={{ width: "100%" }}>
                <div className="col-md-7 col-12 gap-2 ps-5">
                    <div className="row">
                        <div className="col-12" style={{ marginTop: "5%" }}>
                            <p id="homeTitle">Meetings Vault</p>
                            <p className="homeSubText" >{t("p1")}</p>
                            <p className="homeSubText" >{t("p2")}</p>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p className="homeSubText2" >{t("p3")}</p>
                            <p className="homeSubText2" >{t("p4")}</p>
                            <p className="homeSubText2" >{t("p5")}</p>
                            <p className="homeSubText2" >{t("p6")}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12 pt-md-5" >
                    <div className="row d-flex  align-items-sm-center" id="homepageBtnDiv">
                        <div className="col-md-12 col-12">
                            {cookies.token ? <button type="button" className="btn btn-primary myBtn" id="addProyectModalBtn" data-bs-toggle="modal" data-bs-target="#modAddProyect" style={{ position: "absolute", zIndex: "100" }}>{t("b1.1")}</button>
                                :
                                <div className="row justify-content-end">
                                    <div className="col-md-8 col-5">
                                        <Link to={"/NewProy"} id="startLink"><button type="button" className="btn btn-primary myBtn">{t("b1")}</button></Link>
                                    </div>
                                    <div className="col-md-8 col-1">
                                        {screensize ? null : <p className="homeSubText mt-2" style={{ color: "white", fontSize: "1.1rem", fontWeight: "400" }}>{t("p7")}</p>}
                                    </div>
                                    <div className="col-md-8 col-5">
                                        <Link to={"/login"} id="startLink"><button type="button" className="btn btn-primary myBtn">{t("b2")}</button></Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-end" style={{ width: "100%", position: "absolute", bottom: "0" }}>
                <svg xmlns="http://www.w3.org/2000/svg" id="svgHomeBanner" style={{ width: "100%", margin: "0", padding: "0" }} viewBox="0 0 1440 320">
                    <path fill="#ffffff" fillOpacity="1" d="M0,96L48,117.3C96,139,192,181,288,218.7C384,256,480,288,576,282.7C672,277,768,235,864,234.7C960,235,1056,277,1152,282.7C1248,288,1344,256,1392,240L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
            </div>

        </div>

    )
}

export default NewProject