import { useTranslation } from "react-i18next"
import vidNewProject from "@images/vidNewProjectGuide2.gif"
import vidNewProject2 from "@images/vidNewProject33.gif"
import vidNewProject3 from "@images/vidNewMeeting33.gif"
import vidNewProject4 from "@images/vidNewEditMeeting.gif"
import vidNewProject5 from "@images/vidPermissions.gif"
import { useState } from "react"
import useScreensize from "../hooks/useScreenSize"
function AllGuides() {
    const screensize = useScreensize()
    const [newProject, setNewProject] = useState(false)
    const [newMeeting, setNewMeeting] = useState(false)
    const [newEditMeeting, setNewEditMeeting] = useState(false)
    const [newPermissions, setNewPermissions] = useState(false)
    const { t } = useTranslation(["trAllGuides"]);
    const handleRender = (selector) => {
        switch (selector) {
            case "M1":
                setNewMeeting(false)
                setNewPermissions(false)
                setNewEditMeeting(false)
                setNewProject(!newProject)
                break
            case "M2":
                setNewProject(false)
                setNewPermissions(false)
                setNewEditMeeting(false)
                setNewMeeting(!newMeeting)
                break
            case "M3":
                setNewProject(false)
                setNewMeeting(false)
                setNewPermissions(false)
                setNewEditMeeting(!newEditMeeting)
                break
            case "M4":
                setNewProject(false)
                setNewMeeting(false)
                setNewEditMeeting(false)
                setNewPermissions(!newPermissions)
                break
            default:
                break
        }
    }
    return (
        <div className="container-fluid menuProjects" id="allGuides">
            <div className="row">
                <div className="col my-5">
                    <h3 id="allGuidesTitle">{t("title")}</h3>
                </div>
            </div>
            <div className="row justify-content-around">
                <div className="col-12 col-lg-3">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" onClick={() => handleRender("M1")}>
                                    <h4> {t("btnNewProyect")}</h4>
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>{t("p1")}</p>
                                    <p>{t("p2")}</p>
                                    <p>{t("p3")}</p>
                                    {screensize &&
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <button className="allGuidesGifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModal" style={{ border: "none" }}><img src={vidNewProject} className="d-block w-100" alt="" /></button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" onClick={() => handleRender("M2")}>
                                    <h4>{t("btnNewMeeting")}</h4>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>{t("p4")}</p>
                                    <p>{t("p5")}</p>
                                    {screensize &&
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <button className="allGuidesGifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModal"><img src={vidNewProject3} className="d-block w-100" alt="" /></button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" onClick={() => handleRender("M3")}>
                                    <h4> {t("btnEditMeeting")}</h4>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>{t("p6")}</p>
                                    <p>{t("p7")}</p>
                                    <p>{t("p8")}</p>
                                    <p>{t("p9")}</p>
                                    <p>{t("p10")}</p>
                                    {screensize &&
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <button className="allGuidesgifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModal"><img src={vidNewProject4} className="d-block w-100" alt="" /></button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" onClick={() => handleRender("M4")}>
                                    <h4> {t("btnPermissions")}</h4>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p>{t("p11")}</p>
                                    <p>{t("p12")}</p>
                                    <p>{t("p13")}</p>
                                    <p>{t("p14")}</p>
                                    {screensize &&
                                        <div className="row justify-content-center">
                                            <div className="col-6">
                                                <button className="allGuidesgifBtn" type="button" data-bs-toggle="modal" data-bs-target="#gifModal"><img src={vidNewProject5} className="d-block w-100" alt="" /></button>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {screensize ? null : <div className="col-9">
                    {newProject ?
                        <>
                            <div className="row">
                                <div className="col-6">
                                    <img src={vidNewProject} className="d-block w-100" alt="" />
                                </div>
                                <div className="col-6">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">

                                </div>
                                <div className="col-6">
                                    <img src={vidNewProject2} className="d-block w-100" alt="" />
                                </div>
                            </div>
                        </> :
                        null}
                    {newMeeting ?
                        <div className="row">
                            <div className="col-9">
                                <img src={vidNewProject3} className="d-block w-100" alt="" />
                            </div>
                        </div>
                        :
                        null}
                    {newEditMeeting ?
                        <div className="row">
                            <div className="col-9">
                                <img src={vidNewProject4} className="d-block w-100" alt="" />
                            </div>
                        </div>
                        :
                        null}
                    {newPermissions ?
                        <div className="row">
                            <div className="col-9">
                                <img src={vidNewProject5} className="d-block w-100" alt="" />
                            </div>
                        </div>
                        :
                        null}

                </div>}
            </div>
            {/* mobile modal */}
            <div className="modal fade" id="gifModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-body" id="allGuidesModalBody" >
                            <div className="row justify-content-end">
                                <div className="col-3">
                                    <button type="button" id="allGuidesCloseBtn" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                                </div>
                            </div>

                            {newProject ?
                                <div className="row align-items-center allGuidesDivGif" >
                                    <div className="col-12">
                                        <img src={vidNewProject} className="d-block w-100" alt="" />
                                    </div>
                                    <div className="col-12">
                                        <img src={vidNewProject2} className="d-block w-100" alt="" />
                                    </div>
                                </div>
                                : newMeeting ?
                                    <div className="row align-items-center allGuidesDivGif" >
                                        <div className="col-12">
                                            <img src={vidNewProject3} className="d-block w-100" alt="" />
                                        </div>
                                    </div>
                                    : newEditMeeting?
                                    <div className="row align-items-center allGuidesDivGif" >
                                        <div className="col-12">
                                            <img src={vidNewProject4} className="d-block w-100" alt="" />
                                        </div>
                                    </div>
                                    :newPermissions?
                                    <div className="row align-items-center allGuidesDivGif" >
                                        <div className="col-12">
                                            <img src={vidNewProject5} className="d-block w-100" alt="" />
                                        </div>
                                    </div>:null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllGuides