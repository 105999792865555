import { useTranslation } from "react-i18next"
import enIco from "../resources/images/en.png"
import esIco from "../resources/images/es.png"


const LanguageSelector = () => {
    const { i18n } = useTranslation()
    const handleLanguage = (lng) => {
        i18n.changeLanguage(lng)
    }
    return (
        <div className=" d-lg-flex justify-content-lg-end">
            <button type="button" className="btn langBtn " onClick={() => handleLanguage("en")}><img src={enIco} className="rounded mx-auto languageIco" alt="..." /></button>
            <button type="button" className="btn langBtn" onClick={() => handleLanguage("es")}><img src={esIco} className="rounded mx-auto languageIco" alt="..." /></button>
        </div>


    )
}

export default LanguageSelector