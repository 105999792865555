import { useEffect, useState } from "react"
import { useLocation } from "react-router"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { handleExpired } from "../functions/functions.js"
function MeetSumDnD() {
    const { t } = useTranslation(["trEditMeetingPage"]);
    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state
    const user = useSelector((state) => state.user.name)
    const currentDate = new Date().toLocaleDateString(undefined, { month: "long", year: "numeric", day: "numeric" })
    const dbDate = new Date()
    const finalProyectName = data.data.proyName
    const [finalMeetingName, setFinalMeetingName] = useState(data.data.meetingName)
    const [finalPartList, setFinalPartList] = useState(data.data.participantList)
    const [finalTaskList, setFinalTaskList] = useState(data.taskList)
    const [finalParagraph, setFinalParagraph] = useState("")
    const [delPart, setDelPart] = useState(false)
    const [delTask, setDelTask] = useState(false)
    const [meetNameModal, setMeetNameModal] = useState(false)
    const [addTaskModal, setAddTaskModal] = useState(false)
    const [addParagraphModal, setAddParagraphModal] = useState(false)
    const [taskName, setTaskName] = useState("")
    const [participantName, setParticipantName] = useState("")
    const [meetName, setMeetName] = useState("")
    const [imgLogo, setImgLogo] = useState(null)
    const [imgId, setImgId] = useState("")
    const [tempImgId, setTempImgId] = useState("")
    const [logoUrl, setLogoUrl] = useState("")
    const [logoPreset, setLogoPreset] = useState(true)
    const [saveSpinner, setSaveSpinner] = useState(false)
    const [saveSpinner2, setSaveSpinner2] = useState(false)
    const URL_API = process.env.REACT_APP_API_URL
    useEffect(() => {
        axios.get(`${URL_API}/imghandler/userLogo`, { withCredentials: true })
            .then((res) => {
                setLogoUrl(res.data.data.url)
                setTempImgId(res.data.data.id)
            })
    }, [])
    const taskReorder = (list, startIndex, endIndex) => {
        const result = [...list]
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }
    const cleanModalBtn = () => {
        setDelPart(false)
        setMeetNameModal(false)
        setAddTaskModal(false)
    }
    const cleanPartModal = () => {
        document.getElementById("inputIndex").value = ""
        document.getElementById("inputName").value = ""
        document.getElementById("inputEmail").value = ""
        document.getElementById("inputRole").value = ""
        setDelPart(false)
        document.getElementById("closePartModalBtn").click()
    }
    const cleanTaskModal = () => {
        document.getElementById("inputNameTask").value = ""
        document.getElementById("inputResTask").value = ""
        document.getElementById("inputDescTask").value = ""
        document.getElementById("inputDeadLineTask").value = ""
        cleanModalBtn()
        document.getElementById("closeTaskModalBtn").click()
    }
    const cleanPharagraphModal = () => {
        document.getElementById("inputParagraph").value = ""
        setAddParagraphModal(false)
    }
    const addParticipant = () => {
        let index = document.getElementById("inputIndex").value
        let partName = document.getElementById("inputName").value
        let partEmail = document.getElementById("inputEmail").value
        let partRole = document.getElementById("inputRole").value
        let obj = { partName, partEmail, partRole }
        if (!index) {
            setFinalPartList(finalPartList => [...finalPartList, obj])
        } else {
            let newArr = [...finalPartList]
            newArr[index] = obj
            setFinalPartList(newArr)
        }
        setParticipantName("")
        cleanPartModal()
    }
    const editParticipant = (index) => {
        setParticipantName("random")
        let editParticipantItem = finalPartList[index]
        document.getElementById("addPartModalBtn").click()
        document.getElementById("inputName").value = editParticipantItem.partName
        document.getElementById("inputEmail").value = editParticipantItem.partEmail
        document.getElementById("inputRole").value = editParticipantItem.partRole
        document.getElementById("inputIndex").value = index
        setDelPart(true)
    }
    const delParticipant = () => {
        let index = document.getElementById("inputIndex").value
        let deletePart = [...finalPartList]
        deletePart.splice(index, 1)
        setFinalPartList(deletePart)
        cleanPartModal()
    }
    const editMeetName = () => {
        setFinalMeetingName(document.getElementById("inputMeetingName").value)
        document.getElementById("inputMeetingName").value = ""
        setMeetNameModal(false)
        document.getElementById("closeMeetModalBtn").click()
    }
    const addTaskList = () => {
        let index = document.getElementById("inputIndexTask").value
        let taskName = document.getElementById("inputNameTask").value
        let taskDesc = document.getElementById("inputDescTask").value
        let taskResp = document.getElementById("inputResTask").value
        let taskDeadLine = document.getElementById("inputDeadLineTask").value
        let obj = { taskName, taskDesc, taskResp, taskDeadLine }
        if (!index) {
            setFinalTaskList(finalTaskList => [...finalTaskList, obj])
        } else {
            let newArr = [...finalTaskList]
            newArr[index] = obj
            setFinalTaskList(newArr)
        }
        cleanTaskModal()
        cleanModalBtn()
    }
    const delTaskList = () => {
        let index = document.getElementById("inputIndexTask").value
        let deleteTask = [...finalTaskList]
        deleteTask.splice(index, 1)
        setFinalTaskList(deleteTask)
        cleanTaskModal()
        cleanModalBtn()
    }
    const editTask = (index) => {
        setTaskName("random")
        let editTaskItem = finalTaskList[index]
        document.getElementById("addTaskModalBtn").click()
        setTimeout(() => {
            document.getElementById("inputNameTask").value = editTaskItem.taskName
            document.getElementById("inputResTask").value = editTaskItem.taskResp
            document.getElementById("inputDescTask").value = editTaskItem.taskDesc
            document.getElementById("inputDeadLineTask").value = editTaskItem.taskDeadLine
            document.getElementById("inputIndexTask").value = index
        }, 500)
        setDelTask(true)
    }

    const addParagraph = (op) => {
        if (op === "1") {
            setAddParagraphModal(true)
            setTimeout(() => {
                document.getElementById("inputParagraph").value = finalParagraph
            }, 500)


        } else {
            let paragraph = document.getElementById("inputParagraph").value
            setFinalParagraph(paragraph)
        }
    }
    const generarMeetPdfandSave = () => {

        let dbName = currentDate + "_" + finalMeetingName
        let URL = `${URL_API}/pdfFile/save`
        let data = {
            dbDate,
            dbName,
            currentDate,
            finalProyectName,
            finalMeetingName,
            finalParagraph,
            finalPartList,
            finalTaskList,
            imgId
        }

        axios.post(URL, data, {
            withCredentials: true,

        })
            .then((res) => {
                if (handleExpired(res.data.data)) {
                    console.log("++++", res)
                    generarMeetPdf()
                    navigate("/meetingedit", { state: { meetid: res.data.data, logoUrl: res.data.logoUrl } })
                }

            })

    }
    const generarMeetPdf = () => {
        let lang = localStorage.getItem("i18nextLng")
        let dbName = currentDate + finalMeetingName
        let URL = `${URL_API}/pdfFile/`
        let data = {
            dbDate,
            dbName,
            currentDate,
            finalProyectName,
            finalMeetingName,
            finalParagraph,
            finalPartList,
            finalTaskList,
            lang,
            imgId
        }
        axios.post(URL, data, {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/pdf"
            }
        })
            .then((res) => {
                let blob = new Blob([res.data], { type: "application/pdf" }), url = window.URL.createObjectURL(blob)
                window.open(url)

            })
    }
    const handlelogo = () => {
        let fileImg = document.getElementById("logofile")
        let imagePreview = document.getElementById("imagePreviewLogo")
        fileImg.addEventListener("change", function () {
            const file = this.files[0]
            if (file) {
                setImgLogo(file)
                const reader = new FileReader()
                imagePreview.style.display = "block"
                reader.addEventListener("load", function () {
                    imagePreview.setAttribute("src", this.result)
                })
                reader.readAsDataURL(file)
            } else {
                imagePreview.style.display = null
                imagePreview.setAttribute("src", "")
                setImgLogo(null)
            }
        })

    }
    const handleUploadLogo = () => {
        setSaveSpinner(true)
        axios.get(`${URL_API}/imghandler`, { withCredentials: true })
            .then((res) => {
                const formData = new FormData()
                const fileObj = imgLogo
                formData.append("fileType", fileObj.type)
                setImgId(res.data.id)
                axios.put(res.data.data, fileObj, {
                    headers: {
                        "Content-Type": fileObj.type
                    }
                })
                    .then((res) => {
                        if (logoUrl) {
                            axios.get(`${URL_API}/imgHandler/userLogo`, { withCredentials: true })
                                .then((res) => {
                                    setLogoUrl(res.data.data.url)
                                })
                        }
                    })
                setSaveSpinner(false)
                setSaveSpinner2(true)
                setTimeout(()=>{
                    setSaveSpinner2(false)
                },"1000")
            })
    }
    const addLogoPreset = () => {
        let imagePreview = document.getElementById("imagePreviewLogo")
        if (logoPreset) {
            imagePreview.style.display = "block"
            imagePreview.src = logoUrl
            setLogoPreset(false)
            setImgId(tempImgId)
        } else {
            imagePreview.style.display = null
            imagePreview.src = ""
            setLogoPreset(true)
            setImgId("")
        }

    }
    return (
        <div className="container-fluid reportPages py-3">
            <div className="row">
                <div className="col">
                    <h3> {t("previewTitle")}</h3>
                </div>
            </div>
            <div className="row my-5">
                <div className="col-lg-6  border border-4 shadow m-lg-3 meetingReport" style={{ backgroundColor: "white" }}>
                    <div className="row my-5">
                        <div className="col text-start ms-2">
                            {currentDate}
                        </div>
                        <div className="col-6 text-end me-5 imageLogoPreview" id="imgLogoPreview">
                            <img src="" alt="" className="imgPreview" id="imagePreviewLogo" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col"><h4 className="my-5">{finalProyectName}</h4></div>
                    </div>
                    <div className="row">
                        <div className="col text-start ms-2"><h5 className="my-5">{finalMeetingName}</h5></div>
                    </div>
                    {finalParagraph ?
                        <div className="row my-5">
                            <div className="col text-start ms-2">{finalParagraph}</div>
                        </div>
                        : <></>
                    }
                    <div className="row my-3">
                        <div className="col text-start ms-2"><h6>{t("participants")}</h6></div>
                    </div>
                    <div className="row">
                        <div className="col mx-2">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">{t("tName")}</th>
                                        <th scope="col">{t("tRole")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finalPartList.map((e, index) => (
                                        <tr key={index}>
                                            <td name={index} onDoubleClick={(e) => editParticipant(e.target.getAttribute("name"))}>{e.partName}</td>
                                            <td name={index} onDoubleClick={(e) => editParticipant(e.target.getAttribute("name"))}>{e.partRole}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col text-start ms-2"><h6>{t("tasks")}</h6></div>
                    </div>
                    <div className="row mx-2">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">{t("tResponsable")}</th>
                                            <th scope="col">{t("tDescription")}</th>
                                            <th scope="col">{t("tDeadline")}</th>
                                        </tr>
                                    </thead>
                                    <DragDropContext onDragEnd={(result) => {
                                        const { source, destination } = result
                                        if (!destination) {
                                            return
                                        }
                                        if (source.index === destination.index) {
                                            return
                                        }
                                        setFinalTaskList(prevTasks => taskReorder(prevTasks, source.index, destination.index))

                                    }}>
                                        <Droppable droppableId="tasks">
                                            {(droppableProvided) => (
                                                <tbody className="table-group-divider" {...droppableProvided.droppableProps}
                                                    ref={droppableProvided.innerRef}>
                                                    {finalTaskList.map((e, index) => (
                                                        <Draggable key={e.taskName} draggableId={e.taskName} index={index}>
                                                            {(draggableProvided) => (
                                                                <tr id={index}  {...draggableProvided.draggableProps}
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.dragHandleProps}>
                                                                    <th scope="row" name={index} onDoubleClick={(e) => editTask(e.target.getAttribute("name"))}>{e.taskName}</th>
                                                                    <td>{e.taskResp}</td>
                                                                    <td style={{ wordWrap: "break-word", minWidth: 160, maxWidth: 160, whiteSpace: "normal" }}>{e.taskDesc}</td>
                                                                    <td>{e.taskDeadLine}</td>

                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}

                                                    {droppableProvided.placeholder}
                                                </tbody>)}

                                        </Droppable>
                                    </DragDropContext>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 pt-5">
                    <div className="sticky-top">
                        <div className="row">
                            <div className="d-grid gap-2" style={{ paddingTop: "4rem" }}>
                                <button type="button" className="btn btn-primary myBtn" id="addPartModalBtn" data-bs-toggle="modal" data-bs-target="#modAddParticipant">
                                    {t("btnAddParticipant")}
                                </button>
                                <button type="button" className="btn btn-primary myBtn" id="editMeetNameModalBtn" data-bs-toggle="modal" data-bs-target="#modAddParticipant" onClick={() => setMeetNameModal(true)}>
                                    {t("btnChangeReunionName")}
                                </button>
                                <button type="button" className="btn btn-primary myBtn" id="addTaskModalBtn" data-bs-toggle="modal" data-bs-target="#modAddParticipant" onClick={() => setAddTaskModal(true)}>
                                    {t("btnAddTask")}
                                </button>
                                <button type="button" className="btn btn-primary myBtn" id="addPharagraphModalBtn" data-bs-toggle="modal" data-bs-target="#modAddParticipant" onClick={() => finalParagraph ? addParagraph("1") : setAddParagraphModal(true)}>
                                    {t("btnAddParagraph")}
                                </button>
                                {user?logoUrl ?
                                    <div>
                                        <div className="row col-6 justify-content-end"> <img src={logoUrl} alt="" className="iconLogo" /></div>
                                        <button type="button" className="btn btn-primary myBtn" onClick={addLogoPreset} style={{ "marginRight": "10px" }}>{logoPreset ? t("btnAddLogo") : t("btnRemoveLogo")}</button>
                                        <button type="button" className="btn btn-primary myBtn">
                                            <label htmlFor="logofile" style={{ width: "100%" }}>
                                                {t("btnNewLogo")}
                                                <input type="file" name="logoFile" id="logofile" onClick={handlelogo} style={{ display: "none" }} />
                                            </label>
                                        </button>
                                    </div>
                                    :
                                    <button type="button" className="btn btn-primary myBtn" id="addLogo">
                                        <label htmlFor="logofile" style={{ width: "100%" }}>
                                            {t("btnAddLogo")}
                                            <input type="file" name="logoFile" id="logofile" onClick={handlelogo} style={{ display: "none" }} />
                                        </label>
                                    </button>:null}
                                {imgLogo ? <div className="col-3 d-flex offset-9">
                                    {saveSpinner ? <div className="spinner-border text-success" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div> : saveSpinner2 ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="lime" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg> :
                                        <button type="button" className="btn btn-primary myBtn" id="uploadLogo" onClick={handleUploadLogo}>
                                            {t("btnSaveLogo")}
                                        </button>
                                    }
                                </div> : null}


                            </div>
                        </div>
                        <div className="row">
                            <div className=" d-grid gap-2 " style={{ paddingTop: "4rem" }}>
                                {user ? <><button type="button" className="btn btn-primary myBtn" id="generarPdf" name="generarPdf" onClick={generarMeetPdfandSave}>
                                    {t("btnGenerateSave")}
                                </button><button type="button" className="btn btn-primary myBtn" id="generarPdf" name="generarPdf" onClick={generarMeetPdf}>
                                        {t("btnGenerate")}
                                    </button></>
                                    : <button type="button" className="btn btn-primary myBtn" id="generarPdf" name="generarPdf" onClick={generarMeetPdf}>
                                        {t("btnGenerate")}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="modAddParticipant" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="modal-title fs-5" id="exampleModalLabel"></h3>
                                </div>
                                {addParagraphModal ?
                                    <div className="modal-body">
                                        <textarea type="textArea" rows={10} cols={35} className="htmlForm-control" id="inputParagraph" placeholder={t("modPhParagraph")} />
                                    </div>
                                    : addTaskModal ?
                                        <div className="modal-body">
                                            <input style={{ marginRight: "5px", marginBottom: "5px" }} type="text" className="htmlForm-control" id="inputNameTask" onChange={(e) => { setTaskName(e.target.value) }} placeholder={t("modPhTaskName")} />
                                            <input style={{ marginBottom: "5px" }} type="text" className="htmlForm-control" id="inputResTask" placeholder={t("modPhResponsable")} />
                                            <textarea type="textArea" rows={10} cols={35} className="htmlForm-control" id="inputDescTask" placeholder={t("modPhDescription")} />
                                            <input type="text" className="htmlForm-control" id="inputDeadLineTask" placeholder={t("modPhDeadline")} />
                                            <input type="text" className="htmlForm-control" id="inputIndexTask" readOnly value={undefined} style={{ visibility: "hidden" }} />
                                        </div>
                                        : meetNameModal ?
                                            <div className="modal-body">
                                                <input type="text" className="htmlForm-control" id="inputMeetingName" onChange={(e) => { setMeetName(e.target.value) }} placeholder={t("modPhMeetingName")} />
                                            </div>
                                            : <div className="modal-body">
                                                <input style={{ marginRight: "5px", marginBottom: "5px" }} type="text" className="htmlForm-control" id="inputName" onChange={(e) => setParticipantName(e.target.value)} placeholder={t("modPhParticipant")} />
                                                <input type="text" className="htmlForm-control" id="inputRole" placeholder={t("modPhRol")} />
                                                <input style={{ marginRight: "5px", marginBottom: "5px" }} type="text" className="htmlForm-control" id="inputEmail" placeholder={t("optional")} />
                                                <input type="text" className="htmlForm-control" id="inputIndex" readOnly value={undefined} style={{ visibility: "hidden" }} />
                                            </div>}
                                {addParagraphModal ?
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="closeTaskModalBtn" data-bs-dismiss="modal" onClick={() => cleanPharagraphModal()}>{t("modBtnClose")}</button>
                                        <button type="button" className="btn myBtn" onClick={addParagraph}>{t("modBtnSave")}</button>
                                    </div>
                                    : addTaskModal ?
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" id="closeTaskModalBtn" data-bs-dismiss="modal" onClick={() => cleanTaskModal()}>{t("modBtnClose")}</button>
                                            <button type="button" className="btn myBtn" disabled={taskName ? false : true} onClick={addTaskList}>{t("modBtnSave")}</button>
                                            <button type="button" className="btn myBtn" onClick={delTaskList} style={delTask ? { visibility: "visible" } : { visibility: "hidden" }}>{t("modBtnDelete")}</button>
                                        </div>
                                        : meetNameModal ?
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" id="closeMeetModalBtn" data-bs-dismiss="modal" onClick={() => cleanModalBtn()}>{t("modBtnClose")}</button>
                                                <button type="button" className="btn myBtn" disabled={meetName ? false : true} onClick={editMeetName}>{t("modBtnSave")}</button>
                                            </div>
                                            : <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" id="closePartModalBtn" data-bs-dismiss="modal" onClick={() => cleanPartModal()}>{t("modBtnClose")}</button>
                                                <button type="button" className="btn myBtn" disabled={participantName ? false : true} onClick={addParticipant}>{t("modBtnSave")}</button>
                                                <button type="button" className="btn myBtn" onClick={delParticipant} style={delPart ? { visibility: "visible" } : { visibility: "hidden" }}>{t("modBtnDelete")}</button>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MeetSumDnD

