import {createSlice} from "@reduxjs/toolkit"

const initialState={
    name:"",
    proyects:[],
}

export const userSlice=createSlice({
    name:"user",
    initialState,
    reducers:{
        changeUser:(state,action)=>{
            const name=action.payload
            state.name=name
        },
        changeProyects:(state,action)=>{
            state.proyects=action.payload
        }

        }
    }
)

export const {changeUser,changeProyects}=userSlice.actions

export default userSlice.reducer