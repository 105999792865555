import { useState } from "react"
import axios from "axios"
import { useTranslation } from "react-i18next"
function PasswordRecovery() {
    const { t } = useTranslation(["trPassRecovery"]);
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [recoverStatus, setRecoverStatus] = useState("")
    const URL_API = process.env.REACT_APP_API_URL
    const URL = `${URL_API}/userauth/recoverPass`
    const handleRecoveryPassword = () => {
        let lang=localStorage.getItem("i18nextLng")
        setLoading(true)
        let message=t("message1")
        let message2=t("message2")
        axios.post(URL, { email: email ,lang:lang}, { withCredentials: true })
            .then((res) => {
                console.log(res)
                if (res.data.error) {
                    setRecoverStatus(<p style={{ color: "red" }}>{message}</p>)
                } else {
                    setRecoverStatus(<p style={{ color: "green" }}>{message2}</p>)
                }
                setLoading(false)

            })
    }
    const goHome=()=>{
        //window.location.replace("http://localhost:5000")
        window.location.replace("https://www.meetingsvault.saloit.com")
    }

    
    return (
        <div className="container-fluid loginPage">
            <div className="row justify-content-center" style={{ paddingTop: "10%" }}>
                <div className="col-lg-3 col-12 border border-3 shadow" style={{ background: "white" }}>
                    <div className="row justify-content-center">
                        <div className="col my-3">
                            <h3>MeetingsVault</h3>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-3">
                        {t("p1")}
                    </div>
                    <form>
                        <div className="row justify-content-center px-3 py-2">
                            <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="E-mail" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        {recoverStatus ?
                            <div className="row">
                                <p>{recoverStatus}</p>
                            </div> : null
                        }
                        {loading ?
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div> :
                            <div className="row justify-content-center px-3 py-2">
                                <button type="button" className="btn btn-primary myBtn" onClick={handleRecoveryPassword}>{t("btnSend")}</button>
                            </div>
                        }
                        {recoverStatus ?
                            <div className="row justify-content-center px-3 py-2">
                                <button type="button" className="btn btn-primary myBtn" onClick={goHome}>{t("btnInicio")}</button>
                            </div>:null
                        }
                    </form>
                </div>

            </div>
        </div>
    )
}

export default PasswordRecovery