import Cookies from "universal-cookie"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { changeProyects } from "../redux/userSlice.js"
import { useEffect, useState } from "react"
import swal from "sweetalert"
import { useTranslation } from "react-i18next"
import { handleExpired } from "../functions/functions.js"
function AllProyects() {
    const { t } = useTranslation(["trAllProyects"]);
    const cookies = new Cookies()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const proyects = useSelector((state) => state.user.proyects)
    const [filter, setFilter] = useState("")
    const [proyName, setProyName] = useState("")
    const [proyIndex, setProyIndex] = useState("")
    const [proyId, setProyId] = useState("")
    const [participantProyect, setParticipantProyect] = useState([])
    const [closedProjects, setClosedProjects] = useState([])
    const [list, setList] = useState([])
    const [checked, setChecked] = useState(false)
    const [closeChecked, setCloseChecked] = useState(false)
    const URL_API = process.env.REACT_APP_API_URL
    useEffect(() => {
        if (checked) {
            setProyName("")
            setProyIndex("")
            let previous2 = document.getElementsByClassName("myActive")
            let previous3 = document.getElementsByClassName("selected")
            if (previous2.length > 0) {
                previous2[0].className = previous2[0].className.replace(" myActive", " notActive")
            }
            if (previous3.length > 0) {
                previous3[0].className = previous3[0].className.replace(" selected", " notSelected")
            }
            setList(participantProyect)
        } else if (closeChecked) {
            setProyName("")
            setProyIndex("")
            let previous2 = document.getElementsByClassName("myActive")
            let previous3 = document.getElementsByClassName("selected")
            if (previous2.length > 0) {
                previous2[0].className = previous2[0].className.replace(" myActive", " notActive")
            }
            if (previous3.length > 0) {
                previous3[0].className = previous3[0].className.replace(" selected", " notSelected")
            }
            setList(closedProjects)
        } else {
            setProyName("")
            setProyIndex("")
            let previous2 = document.getElementsByClassName("myActive")
            let previous3 = document.getElementsByClassName("selected")
            if (previous2.length > 0) {
                previous2[0].className = previous2[0].className.replace(" myActive", " notActive")
            }
            if (previous3.length > 0) {
                previous3[0].className = previous3[0].className.replace(" selected", " notSelected")
            }
            setList(proyects)
        }
    }, [checked, participantProyect, proyects, closeChecked, closedProjects])

    let pattern = new RegExp(`\\b` + filter, `i`)
    const handleCleanActive = () => {
        setProyName("")
        setProyIndex("")
        let previous2 = document.getElementsByClassName("myActive")
        let previous3 = document.getElementsByClassName("selected")
        if (previous2.length > 0) {
            previous2[0].className = previous2[0].className.replace(" myActive", " notActive")
        }
        if (previous3.length > 0) {
            previous3[0].className = previous3[0].className.replace(" selected", " notSelected")
        }
    }
    const handleBorrar = (e) => {
        let lang = localStorage.getItem("i18nextLng")
        let proyectName = e.target.getAttribute("name")
        if (cookies.get("token")) {
            axios.post(`${URL_API}/api/deleteproyect`, { proyectName, lang }, {
                withCredentials: true
            }).then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal(res.data.alert)
                    } else {
                        axios.get(`${URL_API}/api/getproyectlist`, {
                            withCredentials: true
                        }).then((res) => {
                            if (res.data.data) {

                                dispatch(changeProyects(res.data.data))

                            }
                        })
                    }
                }

            })
        }
    }
    const handleOpen = (e) => {
        let param1 = e.target.getAttribute("name")
        let param2 = e.target.getAttribute("tag")
        let param3 = false
        if (checked || closeChecked) {
            param3 = true
        }
        navigate("/proyectmanager", { state: { param1, param2, param3 } })

    }
    const handleActive = (e) => {
        setProyName(e.target.id)
        setProyIndex(e.target.getAttribute("index"))
        setProyId(e.target.getAttribute("tag"))
        let previous2 = document.getElementsByClassName("myActive")
        let previous3 = document.getElementsByClassName("selected")
        if (previous2.length > 0) {
            previous2[0].className = previous2[0].className.replace(" myActive", " notActive")
        } try {
            e.target.getElementsByClassName("row")[0].className = e.target.getElementsByClassName("row")[0].className.replace(" notActive", " myActive")
        }
        catch { console.log("click") }
        if (previous3.length > 0) {
            previous3[0].className = previous3[0].className.replace(" selected", " notSelected")
        } try {
            e.target.getElementsByClassName("row1")[0].className = e.target.getElementsByClassName("row1")[0].className.replace(" notSelected", " selected")
        }
        catch { console.log("click") }
    }
    const addParticipant = () => {
        let lang = localStorage.getItem("i18nextLng")
        let participant = document.getElementById("inputParticipant").value
        if (cookies.get("token")) {
            axios.post(`${URL_API}/api/updateProyect`, { proyName, participant, lang }, {
                withCredentials: true
            }).then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal(res.data.alert, {
                            buttons: {
                                cancel: t("modCloseBtn"),
                                OK: t("swalBtnSend")
                            }
                        }).then((value) => {
                            if (value === "OK") {
                                swal({ icon: "success" })
                                let lang = localStorage.getItem("i18nextLng")
                                let data = {
                                    participant,
                                    proyName,
                                    proyId,
                                    lang
                                }
                                axios.post(`${URL_API}/userauth/invite`, data, { withCredentials: true })
                            }
                            axios.get(`${URL_API}/api/getproyectlist`, {
                                withCredentials: true
                            }).then((res) => {
                                console.log(res.data.data)
                                if (res.data.data) {
                                    dispatch(changeProyects(res.data.data))
                                    document.getElementById("inputParticipant").value = ""
                                }
                            })
                        })
                        

                    } else {
                        axios.get(`${URL_API}/api/getproyectlist`, {
                            withCredentials: true
                        }).then((res) => {
                            if (res.data.data) {
                                dispatch(changeProyects(res.data.data))
                                document.getElementById("inputParticipant").value = ""
                            }
                        })
                    }
                }
            })
        }

    }
    const deleteParticipant = (e) => {
        let lang = localStorage.getItem("i18nextLng")
        let participant = e.target.id
        if (cookies.get("token")) {
            axios.post(`${URL_API}/api/delParticipant`, { proyName, participant, proyId, lang }, {
                withCredentials: true
            }).then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal(res.data.alert)
                    } else {
                        axios.get(`${URL_API}/api/getproyectlist`, {
                            withCredentials: true
                        }).then((res) => {
                            if (res.data.data) {

                                dispatch(changeProyects(res.data.data))

                            }
                        })
                    }
                }
            })
        }
    }

    const handleParticpantProyects = (e) => {
        if (e.target.checked) {
            setCloseChecked(false)
            axios.get(`${URL_API}/api/getParticipantProyectList`, {
                withCredentials: true
            }).then((res) => {
                if (handleExpired(res.data.data)) {
                    let temp = []
                    res.data.data.map((e) => (
                        temp.push({ "proyId": e })
                    ))
                    setParticipantProyect(temp)
                    setChecked(true)
                }
            })
        } else {
            setChecked(false)
        }

    }
    const handleCloseProyect = (e) => {
        if (e.target.checked) {
            setChecked(false)
            axios.get(`${URL_API}/api/getClosedProyectList`, {
                withCredentials: true
            }).then((res) => {
                if (handleExpired(res.data.data)) {
                    let temp = []
                    res.data.data.map((e) => (
                        temp.push({ "proyId": e.proyId })
                    ))
                    setClosedProjects(temp)
                    setCloseChecked(true)
                }
            })
        } else {
            setCloseChecked(false)
        }
    }
    const deleteOwner = (e) => {
        let lang = localStorage.getItem("i18nextLng")
        let owner = e.target.id
        if (cookies.get("token")) {
            axios.post(`${URL_API}/api/delowner`, { owner, proyId, lang }, {
                withCredentials: true
            }).then((res) => {
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal(res.data.alert)
                    } else {
                        axios.get(`${URL_API}/api/getproyectlist`, {
                            withCredentials: true
                        }).then((res) => {
                            if (res.data.data) {

                                dispatch(changeProyects(res.data.data))

                            }
                        })
                    }
                }
            })
        }
    }
    const addOwner = () => {
        let lang = localStorage.getItem("i18nextLng")
        let newOwner = document.getElementById("inputOwner").value
        if (cookies.get("token")) {
            axios.post(`${URL_API}/api/addowner`, { newOwner, proyId, lang }, {
                withCredentials: true
            }).then((res) => {
                console.log(res)
                if (handleExpired(res.data.data)) {
                    if (res.data.alert) {
                        swal(res.data.alert)
                    } else {
                        axios.get(`${URL_API}/api/getproyectlist`, {
                            withCredentials: true
                        }).then((res) => {
                            if (res.data.data) {
                                dispatch(changeProyects(res.data.data))

                            }
                        })
                    }
                }
            })
        }
    }
    return (
        <div className="container-fluid menuProjects">
            <div className="row">
                <div className="col my-5">
                    <h4>{t("title")}</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-12">
                    <div className="row justify-content-center mb-1">
                        <div className="col-md-10 col-12">
                            <input className="form-control" id="searchBox" placeholder={t("search")} onClick={handleCleanActive} onChange={(e) => setFilter(e.target.value)} />
                        </div>
                        <div className="col-md-2 col-10">
                            <div className="row">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={checked} onChange={handleParticpantProyects} />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ fontSize: "12px" }}>{t("switch")}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={closeChecked} onChange={handleCloseProyect} />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault" style={{ fontSize: "12px" }}>{t("switch2")}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-start" >
                        <div className="col justify-content-start">
                            <p id="allPsubtitle">{t("psubtitle")}</p>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="list-group">
                                {list.map((e, index) => {
                                    if (filter === "") {
                                        return (
                                            <li className="list-group-item list-group-item-action" tag={e.proyId._id} index={index} key={index} id={e.proyId.name} onClick={handleActive}>
                                                <div className="row1 notSelected" style={{ pointerEvents: "none" }}>
                                                    <div className="d-flex w-100 justify-content-between" style={{ pointerEvents: "none" }}>
                                                        <h5 className="mb-1">{e.proyId.name}</h5>
                                                        <small>{t("customer")}: {e.proyId.client}</small>
                                                    </div>
                                                    <div className="row notActive" style={{ pointerEvents: "none" }}>
                                                        <div className="col"><button className="btn btn-primary myBtn btn-sm" name={e.proyId._id} onClick={handleBorrar} style={{ pointerEvents: "all" }}>{t("btnDelete")}</button></div>
                                                        <div className="col"><button className="btn btn-primary myBtn btn-sm" name={e.proyId.name} tag={e.proyId._id} onClick={handleOpen} style={{ pointerEvents: "all" }}>{t("btnOpen")}</button></div>
                                                        <div className="col-md-2 col-3">Status: {e.proyId.status ? <strong style={{ color: "green" }}>Open</strong> : <strong style={{ color: "red" }}>Closed</strong>}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    } else {
                                        if (e.proyId.client.match(pattern) !== null || e.proyId.name.match(pattern) !== null) {
                                            return (
                                                <li className="list-group-item list-group-item-action" tag={e.proyId._id} index={index} key={index} id={e.proyId.name} onClick={handleActive}>
                                                    <div className="row1 notSelected" style={{ pointerEvents: "none" }}>
                                                        <div className="d-flex w-100 justify-content-between" style={{ pointerEvents: "none" }}>
                                                            <h5 className="mb-1">{e.proyId.name}</h5>
                                                            <small>Cliente: {e.proyId.client}</small>
                                                        </div>
                                                        <div className="row notActive" style={{ pointerEvents: "none" }}>
                                                            <div className="col"><button className="btn btn-primary myBtn btn-sm" name={e.proyId._id} onClick={handleBorrar}>{t("btnDelete")}</button></div>
                                                            <div className="col"><button className="btn btn-primary myBtn btn-sm" name={e.proyId.name} tag={e.proyId._id} onClick={handleOpen} style={{ pointerEvents: "all" }}>{t("btnOpen")}</button></div>
                                                            <div className="col-md-2 col-3">Status: {e.proyId.status ? <strong style={{ color: "green" }}>Open</strong> : <strong style={{ color: "red" }}>Closed</strong>}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        } else {
                                            return (<div />)
                                        }
                                    }
                                })}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col">
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-12">
                            {t("participant")}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-12">
                            <div className="list-group">
                                <button className="list-group-item btn btn-outline-light mb-1" type="button" data-bs-toggle="modal" data-bs-target="#addParticipant" disabled={checked || !proyIndex || closeChecked}>+ {t("newParticipant")}</button>
                                {proyIndex ? (
                                    list[proyIndex].proyId.participants.map((e, index) => (
                                        <li className="list-group-item" key={index}>
                                            <div className="row justify-content-between">
                                                <div className="col">{e}</div>
                                                <div className="col-md-2"><button type="button" style={{ border: "none", background: "white" }} id={e} onClick={deleteParticipant}><svg style={{ pointerEvents: "none" }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" color="red" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                </svg></button></div>
                                            </div>
                                        </li>
                                    ))) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-md-5 col-12">
                            {t("owner")}
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-12">
                            <div className="list-group">
                                <button className="list-group-item btn btn-outline-light mb-1" type="button" data-bs-toggle="modal" data-bs-target="#addOwner" disabled={checked || !proyIndex || closeChecked}>+ {t("newParticipant")}</button>
                                {proyIndex ? (
                                    list[proyIndex].proyId.owner.map((e, index) => (
                                        <li className="list-group-item" key={index}>
                                            <div className="row justify-content-between">
                                                <div className="col">{e}</div>
                                                <div className="col-md-2">
                                                    <button type="button" style={{ border: "none", background: "white" }} id={e} onClick={deleteOwner}><svg style={{ pointerEvents: "none" }} xmlns="http://www.w3.org/2000/svg" width="12" height="12" color="red" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                                                    </svg></button>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="addParticipant" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">@</span>
                                <input type="text" className="form-control" placeholder="E-Mail" aria-label="Username" aria-describedby="basic-addon1" id="inputParticipant" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => document.getElementById("inputParticipant").value = ""}>{t("modCloseBtn")}</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={addParticipant}>{t("modSaveBtn")}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="addOwner" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">@</span>
                                <input type="text" className="form-control" placeholder="E-Mail" aria-label="Username" aria-describedby="basic-addon1" id="inputOwner" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => document.getElementById("inputOwner").value = ""}>{t("modCloseBtn")}</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={addOwner}>{t("modSaveBtn")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllProyects