import { useTranslation } from "react-i18next"
function MeetingMini({meeting}) {
    const { t} = useTranslation(["trEditMeetingPage"]);
    return (
        <div className="container meetingReport my-3 mb-5">
            <div className="row">
                <div className="col  border border-4 shadow m-lg-3" style={{ backgroundColor: "white" }}>
                    <div className="row my-3">
                        <div className="col text-start ms-2" style={{fontSize:"12px"}}>
                            {meeting.proyId.date}
                        </div>
                        <div className="col-6 text-end me-5" style={{fontSize:"12px"}}>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col"><h6 className="my-5">{meeting.proyId.proyectName}</h6></div>
                    </div>
                    <div className="row">
                        <div className="col text-start ms-2"><h6 className="my-5">{meeting.proyId.meetingName}</h6></div>
                    </div>
                    {meeting.proyId.paragraph ?
                        <div className="row my-5">
                            <div className="col text-start ms-2" style={{fontSize:"12px"}}>{meeting.proyId.paragraph}</div>
                        </div>
                        : <></>
                    }
                    <div className="row my-3">
                        <div className="col text-start ms-2"><h6>{t("participants")}</h6></div>
                    </div>
                    <div className="row">
                        <div className="col mx-2">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{fontSize:"12px"}}>{t("tName")}</th>
                                        <th scope="col" style={{fontSize:"12px"}}>{t("tRole")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {meeting.proyId.participants.map((e, index) => (
                                        <tr key={index}>
                                            <td style={{fontSize:"12px"}}>{e.partName}</td>
                                            <td style={{fontSize:"12px"}}>{e.partRole}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col text-start ms-2"><h6>{t("tasks")}</h6></div>
                    </div>
                    <div className="row mx-2">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table align-middle">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{fontSize:"12px"}}></th>
                                            <th scope="col" style={{fontSize:"12px"}}>{t("tResponsable")}</th>
                                            <th scope="col" style={{fontSize:"12px"}}>{t("tDescription")}</th>
                                            <th scope="col" style={{fontSize:"12px"}}>{t("tDeadline")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {meeting.proyId.tasks.map((e, index) => (
                                            <tr key={index}>
                                                <th scope="row" style={{fontSize:"12px"}}>{e.taskName}</th>
                                                <td style={{fontSize:"12px"}}>{e.taskResp}</td>
                                                <td style={{ wordWrap: "break-word", minWidth: 160, maxWidth: 160, whiteSpace: "normal", fontSize:"12px"}}>{e.taskDesc}</td>
                                                <td style={{fontSize:"12px"}}>{e.taskDeadLine}</td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetingMini