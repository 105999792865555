function Footer() {
    return (

       
         <div className="container-fluid" id="footer"> 
             <div className="row align-items-end" id="rowFooter">
                 <div className="col" id="colFooter" >
                     <p id="pfooter">Developed by SaloIt</p>
                 </div>
             </div>
         </div>
       
    )
}

export default Footer