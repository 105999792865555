import { useEffect, useState } from "react"

const useScreensize=()=>{
    const [screenSize,setScreensize]=useState({
        width:window.innerWidth,
        height:window.innerHeight
    })
    useEffect(()=>{
        const handleResize=()=>{
            setScreensize({
                width:window.innerWidth,
                height:window.innerHeight
            })
        }
        window.addEventListener("resize",handleResize)
        return()=>{
            window.removeEventListener("resize",handleResize)
        }
    },[])
    if(screenSize.width<=768){
        return true
    // }else if(screenSize.width<=1366){
    //     return "tablet"
    }else{
        return false
    }
    
}

export default useScreensize